import { useState, useEffect, useMemo } from 'react';
import { Spinner } from 'react-bootstrap';
import {
  useGetTerritories,
  useGetRepProspectLists,
  useGetProviderLists,
  useGetTerritoriesRecursive,
} from 'src/hooks';
import { TabSelector } from 'src/components';
import { useLogging, useAuth, useMap } from 'src/context';
import { useTheme, Box, styled, Typography } from '@mui/material';
import { Checkbox } from '@mui/joy';
import { grey } from '@mui/material/colors';

const RepListGroupHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '0.8rem',
  paddingRight: '1.1rem',
  paddingTop: '0.5rem',
  paddingBottom: '0.5rem',
  backgroundColor: grey[100],
  fontWeight: 600,
  borderTop: `1px solid ${grey[300]}`,
  borderBottom: `1px solid ${grey[300]}`,
}));

interface RepsTerritoriesAndListsProps {
  viewSource?: string;
  setFormattedProspectLists?: (prospectLists: any) => void;
  onTabSelected?: (tab: string) => void;
  activeTab?: string;
  tabOptions?: string[];
}

const RepsTerritoriesAndLists = ({
  viewSource = 'managerOverview',
  setFormattedProspectLists,
  onTabSelected,
  activeTab = null,
  tabOptions = null,
}: RepsTerritoriesAndListsProps) => {
  const { user } = useAuth();
  const log = useLogging();

  const [checkedTerritories, setCheckedTerritories] = useState([]);
  const [checkedLists, setCheckedLists] = useState([]);

  const { existingTerritories, setExistingTerritories, editingTerritory } =
    useMap();

  const theme = useTheme();

  // new calls
  const { data, isLoading: isTerritoryListLoading } =
    useGetTerritoriesRecursive(checkedTerritories);
  const { data: territories, isLoading: isTerritoriesLoading } =
    useGetTerritories({
      pageSize: 1000,
    });
  const { data: providerLists, isLoading: isProviderListsLoading } =
    useGetProviderLists();

  useEffect(() => {
    if (!data) return;

    data.forEach((t, index) => {
      const color = theme.palette.territory[index]?.main;
      t.color = color;
    });
    setExistingTerritories(data);
    return () => {
      setExistingTerritories([]);
    };
  }, [data]);

  const groupedTerritories = useMemo(() => {
    if (!territories?.results) return [];
    // group territories by owner
    const grouped = territories?.results?.reduce((acc, territory) => {
      if (!acc[territory.owner.id]) {
        acc[territory.owner.id] = {
          ownerId: territory.owner.id,
          ownerName: `${territory.owner.first_name} ${territory.owner.last_name}`,
          territories: [],
        };
      }
      acc[territory.owner.id].territories.push(territory);
      return acc;
    }, {});

    // convert grouped territories object to array of objects
    const groupedArray = grouped ? Object.values(grouped) : [];

    // Find the index where user.id matches the owner id
    const userTerritoriesIndex = groupedArray.findIndex(
      (group: any) => group.ownerId === user.id
    );

    // If found, move it to the top
    if (userTerritoriesIndex !== -1) {
      const userTerritories = groupedArray.splice(userTerritoriesIndex, 1);
      groupedArray.unshift(...userTerritories);
    }

    return groupedArray;
  }, [territories]);

  const lists = providerLists?.results;

  const { data: prospectLists, isLoading } =
    useGetRepProspectLists(checkedLists);

  const loading =
    isLoading ||
    isTerritoriesLoading ||
    isProviderListsLoading ||
    isTerritoryListLoading;

  useEffect(() => {
    if (!existingTerritories?.length) return;

    const checkedTerritories = existingTerritories.map((t) => t.id);
    setCheckedTerritories(checkedTerritories);
  }, []);

  useEffect(() => {
    // if no prospectLists or loading, set formattedProspectLists to empty array
    if ((!prospectLists || loading) && checkedLists.length === 0) {
      setFormattedProspectLists([]);
      return;
    }
    // set formattedProspectLists to prospectLists
    setFormattedProspectLists(prospectLists);

    return () => {
      // cleanup
      setFormattedProspectLists([]);
    };
  }, [prospectLists, loading]);

  const toggleTerritory = (territory: MedScout.Territory) => {
    if (checkedTerritories.includes(territory.id)) {
      setCheckedTerritories(
        checkedTerritories.filter((t) => t !== territory?.id)
      );

      const newTerritories = existingTerritories.filter(
        (t) => t.id !== territory.id
      );
      setExistingTerritories(newTerritories);
    } else {
      const newTerritories = [...checkedTerritories, territory?.id];
      setCheckedTerritories(newTerritories);
    }
  };

  const toggleList = (selectedList) => {
    const newCheckedValue = !checkedLists.includes(selectedList?.id);
    log.event(`${viewSource}ListToggled`, {
      id: selectedList.id,
      value: newCheckedValue,
    });

    if (checkedLists.includes(selectedList?.id)) {
      setCheckedLists(checkedLists.filter((t) => t !== selectedList?.id));
    } else {
      const newTerritories = [...checkedLists, selectedList?.id];
      setCheckedLists(newTerritories);
    }
  };

  const getAssignedTerritories = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    // is checked
    const isChecked = e.target.checked;

    // get the territory ids
    const assigned = territories?.results
      ?.filter((t) => t.is_canonical)
      ?.map((t) => t.id);

    if (isChecked) {
      const newTerritories = new Set([...checkedTerritories, ...assigned]);

      setCheckedTerritories(Array.from(newTerritories));
    } else {
      const newTerritories = checkedTerritories.filter(
        (t) => !assigned.includes(t)
      );
      setCheckedTerritories(newTerritories);
    }
  };

  const showTabSelector = onTabSelected && tabOptions && activeTab;

  return (
    <>
      {showTabSelector && (
        <Box sx={{ margin: '0 0.6rem 0.6rem 0.6rem' }}>
          <TabSelector
            onTabChange={onTabSelected}
            value={activeTab}
            tabs={tabOptions}
          />
        </Box>
      )}

      <RepListGroupHeader>
        Your Team {loading && <Spinner animation="border" size="sm" />}
      </RepListGroupHeader>
      <Box
        sx={{
          padding: '0.5rem',
          display: 'flex',
          alignItems: 'center',
          justifyContents: 'center',
          gap: '0.5rem',
        }}
      >
        <Checkbox size="sm" onChange={getAssignedTerritories} />
        <Typography
          variant="body2"
          sx={{ paddingVertical: '0.5rem', fontWeight: 500 }}
        >
          Select assigned
        </Typography>
      </Box>
      <Box sx={{ padding: '0.5rem' }}>
        {groupedTerritories?.map((territory: any) => {
          const filteredLists = lists?.filter((list) => {
            return list.owner.id === territory.ownerId;
          });
          return (
            <Box
              key={territory?.ownerId}
              sx={{
                marginBottom: '0.5rem',
                padding: '0.5rem',
                borderTop: '1px solid #e0e0e0',
                '&:first-of-type': {
                  borderTop: 'none',
                  paddingTop: 0,
                },
              }}
            >
              <Typography variant="subtitle2">
                {territory?.ownerName}
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1">Lists</Typography>
                {filteredLists?.length > 0 ? (
                  filteredLists?.map((list) => {
                    return (
                      <Box
                        key={list.id}
                        sx={{
                          paddingLeft: '0.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContents: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <Checkbox
                          key={list.id}
                          size="sm"
                          checked={checkedLists.includes(list.id)}
                          onChange={() => toggleList(list)}
                        />
                        <Typography
                          variant="body2"
                          sx={{ paddingVertical: '0.5rem', fontWeight: 500 }}
                        >
                          {list.name}
                        </Typography>
                      </Box>
                    );
                  })
                ) : (
                  <Typography variant="caption">No Lists</Typography>
                )}

                <Typography variant="subtitle1">Territories</Typography>
                {territory?.territories?.length > 0 ? (
                  territory?.territories
                    ?.sort((a, b) => {
                      // compare by name ordering alphabetically
                      return a?.name?.localeCompare(b?.name);
                    })
                    ?.sort((a, b) => {
                      // canonical territories should be at the top
                      return b?.is_canonical - a?.is_canonical;
                    })
                    ?.map((t) => {
                      const isEditing = editingTerritory?.id === t.id;
                      const label = isEditing ? `${t.name} (editing)` : t.name;
                      return (
                        <Box
                          key={t.id}
                          sx={{
                            paddingLeft: '0.5rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContents: 'center',
                            gap: '0.5rem',
                          }}
                        >
                          <Checkbox
                            key={t.id}
                            size="sm"
                            checked={
                              checkedTerritories.includes(t.id) || isEditing
                            }
                            onChange={() => toggleTerritory(t)}
                            disabled={isEditing}
                          />
                          <Typography
                            variant="body2"
                            sx={{ paddingVertical: '0.5rem', fontWeight: 500 }}
                          >
                            {label}
                          </Typography>
                          {t.is_canonical && (
                            <Typography
                              variant="caption"
                              sx={{
                                color: theme.palette.grey[500],
                                fontWeight: 600,
                              }}
                            >
                              (assigned)
                            </Typography>
                          )}
                        </Box>
                      );
                    })
                ) : (
                  <Typography variant="body2">No Territories</Typography>
                )}
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default RepsTerritoriesAndLists;
