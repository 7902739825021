import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import {
  Card,
  Box,
  Typography,
  FormControl,
  FormGroup,
  CardContent,
  Autocomplete,
  TextField,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import {
  useAddTerritory,
  useDeleteTerritory,
  useEditTerritory,
  useProspectTerritory,
  useGetAllTerritories,
  useGetTerritory,
} from 'src/hooks';

import { getTerritoryDisplayName } from 'src/utils/territory.helpers';
import { useLogging, useAuth, useMap } from 'src/context';
import { grey } from '@mui/material/colors';
import styled from '@emotion/styled';
import TerritoryPanelForm from './TerritoryPanelForm';
import TerritoryDeletionDialog from './TerritoryDeletionDialog';
import { DRAW_MODES } from 'src/components/Map/constants';
import { DISCOVERY_BLADE_NAMES } from '../../constants';
import { TerritoryGroup, TerritoryItem } from './components';

export const NO_TERRITORY_ACTIVE_SELECTION = {
  id: 'USE_MAP_NO_TERRITORY',
  name: 'Map Without Territory',
};

const MANAGE_TERRITORY_MODES = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

const StyledLoadingButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderTopRightRadius: '0.3rem',
  borderBottomRightRadius: '0.3rem',
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  '&:hover': {
    backgroundColor: grey[200],
  },
  '&.MuiButton-outlined': {
    border: `1px solid ${grey[400]}`,
  },
  padding: '0.5rem',
  minWidth: '2.5rem',
  maxWidth: '2.5rem',
  height: '2.5rem',
}));

const TerritoryPanel = ({
  adhocTerritory,
  setAdhocTerritory,
  showRepList,
  showTerritory,
  toggleDrawer,
  onToggleDrawer,
}) => {
  const { user, setUser } = useAuth();
  const { setProspectTerritory } = useProspectTerritory();
  const {
    editingTerritory,
    editTerritory,
    setSelectTerritories,
    tempSelectedStates,
    tempTerritoryCoordinates,
    tempSelectedCounties,
    tempSelectedZipCodes,
    toggleCreateTerritoryMode,
    createNewTerritoryMode,
    setClearOverlay,
    setDrawingManagerDrawMode,
    removeTempSelectedStates,
    removeTempSelectedCounties,
    removeTempSelectedZipCodes,
    removeTempTerritoryCoordinates,
    setExistingTerritories,
    setTerritoriesLoading,
  } = useMap();

  const [activeTerritory, setActiveTerritory] = useState(null);
  const [manageTerritoryMode, setManageTerritoryMode] = useState(null);
  const [deleteTerritory, setDeleteTerritory] = useState(null);
  const [deleteError, setDeleteError] = useState(null);
  const [inlineError, setInlineError] = useState('');
  const [newTerritoryName, setNewTerritoryName] = useState('');
  const [isCanonical, setIsCanonical] = useState(false);
  const [selectedTerritoryRep, setSelectedTerritoryRep] = useState(null);
  const [showValidation, setShowValidation] = useState(false);

  const log = useLogging();

  const { data: myTerritoryData, isLoading: isLoadingTerritories } =
    useGetAllTerritories();

  const territoryId =
    (activeTerritory?.id &&
      activeTerritory?.id !== NO_TERRITORY_ACTIVE_SELECTION.id) ||
    (user?.last_territory?.id &&
      user?.last_territory?.id !== NO_TERRITORY_ACTIVE_SELECTION.id)
      ? user?.last_territory?.id
      : null;

  const {
    data: currentTerritory,
    isLoading: isLoadingGetTerritory,
    isFetching,
  } = useGetTerritory({
    territoryId,
  });

  const isRegularUser = !(user?.company?.manager || user?.is_superuser);
  const isManager =
    Boolean(user?.company?.manager) ||
    Boolean(user?.permissions?.is_manager) ||
    user?.is_superuser;

  const theme = useTheme();

  useEffect(() => {
    setTerritoriesLoading(isLoadingTerritories);
  }, [isLoadingTerritories]);

  const { isLoading: isSavingTerritory, mutateAsync: onAddTerritory } =
    useAddTerritory();
  const { isLoading: isEditingTerritory, mutateAsync: onEditTerritory } =
    useEditTerritory();
  const { isLoading: isDeletingTerritory, mutateAsync: onDeleteTerritory } =
    useDeleteTerritory();

  const territorySelected = (territory) => {
    if (!territory) return;

    if (territory.id === NO_TERRITORY_ACTIVE_SELECTION.id) {
      log.event('useMapWithNoTerritorySelected');
      setProspectTerritory(null);
    } else {
      setProspectTerritory(territory);
    }

    toggleCreateTerritoryMode(false);
    setActiveTerritory(territory);
    setManageTerritoryMode(null);
  };

  useEffect(() => {
    // on teardown ensure selected state is reset
    return () => {
      toggleCreateTerritoryMode(false);
    };
    // purposeful unmount behavior
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isValidTerritory =
      ((!!createNewTerritoryMode || !!editingTerritory) &&
        (!_.isEmpty(tempTerritoryCoordinates) ||
          !_.isEmpty(tempSelectedCounties) ||
          !_.isEmpty(tempSelectedZipCodes))) ||
      !_.isEmpty(tempSelectedStates);
    if (isValidTerritory) {
      const isEditing = !!editingTerritory;
      const sendPoints = tempTerritoryCoordinates.length > 0;

      const data = {
        name: isEditing ? editingTerritory?.name : 'Adhoc Territory',
        user: 'test_user_id',
        is_canonical: false,
      };

      if (!sendPoints) {
        data['boundary_ids'] =
          tempSelectedStates.length > 0
            ? tempSelectedStates
            : tempSelectedCounties.length > 0
            ? tempSelectedCounties
            : tempSelectedZipCodes;
      } else {
        // get the inner array
        const newCoords = !editingTerritory
          ? tempTerritoryCoordinates
          : tempTerritoryCoordinates[0];

        const head = newCoords[0];
        const tail = newCoords[newCoords.length - 1];
        if (!head || !tail) return;
        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          newCoords.push(head);
        }

        // convert list of arrays to array, of array of objects
        const formated = [
          newCoords.map((point) => {
            return { lat: Number(point[1]), lng: Number(point[0]) };
          }),
        ];

        data['points'] = formated;
      }

      setAdhocTerritory({ ...data });

      // if (!adhocTerritory || !editTerritory)
      //   onToggleDrawer(DISCOVERY_BLADE_NAMES.TERRITORY, false);
    } else {
      // If the user clears values we need to make sure to handle that.
      // if (adhocTerritory) onToggleDrawer(DISCOVERY_BLADE_NAMES.REP_LIST, false);
      setAdhocTerritory(null);
    }
  }, [
    editingTerritory,
    createNewTerritoryMode,
    newTerritoryName,
    tempSelectedCounties,
    tempSelectedZipCodes,
    tempTerritoryCoordinates,
    tempSelectedStates,
  ]);

  useEffect(() => {
    if (!myTerritoryData?.length || isLoadingTerritories) return;

    if (myTerritoryData?.length > 0 && user?.last_territory?.id) {
      const territory = myTerritoryData?.find(
        (territory) => territory.id === user?.last_territory?.id
      );

      if (territory) territorySelected(territory);
    } else {
      territorySelected(NO_TERRITORY_ACTIVE_SELECTION);
    }
  }, [myTerritoryData, user?.last_territory?.id]);

  useEffect(() => {
    if (isLoadingTerritories) return;
    if (activeTerritory) {
      const activeTerritories = combinedData()?.filter(
        (territory) => territory?.id === activeTerritory?.id
      );

      const selectedTerritory = currentTerritory
        ? currentTerritory
        : activeTerritories[0]; // Select the first element if it is an array

      setSelectTerritories([selectedTerritory]);
      if (_.isEmpty(selectedTerritory)) setActiveTerritory(null);
    }

    if (
      user &&
      _.isEmpty(myTerritoryData) &&
      !activeTerritory &&
      createNewTerritoryMode
    ) {
      addTerritory();
    }
  }, [
    user,
    myTerritoryData,
    activeTerritory,
    isLoadingTerritories,
    currentTerritory,
  ]);

  const addTerritory = () => {
    setActiveTerritory(null);
    setNewTerritoryName('');
    setIsCanonical(false);
    setSelectedTerritoryRep(user?.id);
    setShowValidation(false);
    toggleCreateTerritoryMode(true);
    setInlineError('');
    setDrawingManagerDrawMode(DRAW_MODES.STATE);
    setManageTerritoryMode(MANAGE_TERRITORY_MODES.CREATE);
  };

  const saveNewTerritory = async () => {
    if (!newTerritoryName) {
      log.event('territorySaveClickedWithoutName');
      setShowValidation(true);
      return;
    }

    if (!selectedTerritoryRep) {
      log.event('territorySaveClickedWithoutRepSelected');
      setInlineError('Please select a rep to assign the territory to.');
      return;
    }

    if (
      _.isEmpty(tempTerritoryCoordinates) &&
      _.isEmpty(tempSelectedCounties) &&
      _.isEmpty(tempSelectedZipCodes) &&
      _.isEmpty(tempSelectedStates)
    ) {
      log.event('territorySaveClickedWithoutCoordinates');
      setInlineError('Please select or draw your territory on the map.');
      return;
    }

    try {
      log.event('territoryCreateSubmitted', {
        assigned_user_id: selectedTerritoryRep,
      });

      const sendPoints = tempTerritoryCoordinates.length > 0;

      const data = {
        name: newTerritoryName,
        user: selectedTerritoryRep,
        is_canonical: isCanonical,
      };

      if (!sendPoints) {
        data['geometry'] =
          tempSelectedStates.length > 0
            ? tempSelectedStates
            : tempSelectedCounties.length > 0
            ? tempSelectedCounties
            : tempSelectedZipCodes;
      } else {
        const head = tempTerritoryCoordinates[0];
        const tail =
          tempTerritoryCoordinates[tempTerritoryCoordinates.length - 1];
        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          tempTerritoryCoordinates.push(head);
        }

        // convert list of arrays to array, of array of objects
        const formated = [
          tempTerritoryCoordinates.map((point) => {
            return { lat: Number(point[1]), lng: Number(point[0]) };
          }),
        ];

        data['points'] = formated;
      }

      const territory = await onAddTerritory({
        ...data,
      });

      setUser((oldUser) => ({
        ...oldUser,
        last_territory: territory,
      }));

      setManageTerritoryMode(null);
      toggleCreateTerritoryMode(false);
      setActiveTerritory(null);
      setDrawingManagerDrawMode(DRAW_MODES.MOVE);
    } catch (err) {
      log.exception('Error creating territory', err.message);
      setInlineError(err.message);
    }
  };

  const saveEditTerritory = async () => {
    try {
      log.event('territoryEditSubmitted', {
        assigned_user_id: selectedTerritoryRep,
      });

      const sendPoints = tempTerritoryCoordinates.length > 0;
      const data = {
        territoryId: editingTerritory.id,
        name: newTerritoryName,
        user_id: selectedTerritoryRep,
        is_canonical: isCanonical,
      };

      if (!sendPoints) {
        data['geometry'] =
          tempSelectedStates.length > 0
            ? tempSelectedStates
            : tempSelectedCounties.length > 0
            ? tempSelectedCounties
            : tempSelectedZipCodes;
      } else {
        // get the inner array
        const newCoords = tempTerritoryCoordinates[0];
        const head = newCoords[0];
        const tail = newCoords[newCoords.length - 1];
        if (head[0] !== tail[0] || head[1] !== tail[1]) {
          newCoords.push(head);
        }

        // convert list of arrays to array, of array of objects
        const formated = [
          newCoords.map((point) => {
            return { lat: Number(point[1]), lng: Number(point[0]) };
          }),
        ];

        data['points'] = formated;
      }

      const territory = await onEditTerritory({
        ...data,
      });

      setUser((oldUser) => ({
        ...oldUser,
        last_territory: territory,
      }));

      setManageTerritoryMode(null);
      setNewTerritoryName('');
      setIsCanonical(false);
      setSelectedTerritoryRep(null);
      setExistingTerritories([]);
      handleCancel();
    } catch (err) {
      log.exception('Error editing territory', err.message);
      const message =
        err.message === 'Something went wrong, please try again.'
          ? 'Move one or more of the points, and please try again.'
          : err.message;
      setInlineError(message);
    }
  };

  const toggleManageTerritory = (mode = null) => {
    if (mode === MANAGE_TERRITORY_MODES.EDIT) {
      setInlineError('');
      setNewTerritoryName(currentTerritory?.name || '');
      setIsCanonical(currentTerritory?.is_canonical || false);
      setSelectedTerritoryRep(currentTerritory?.owner?.id || null);
      setShowValidation(true);
      editTerritory(currentTerritory);
    } else {
      editTerritory(null);
    }
    setManageTerritoryMode(mode);
  };

  const toggleDeleteConfirmation = () => {
    setDeleteTerritory(null);
    setDeleteError(null);
    setActiveTerritory(null);
  };

  const confirmDeleteTerritory = async () => {
    try {
      log.event('territoryDeleteSubmitted');
      await onDeleteTerritory({
        territoryId: deleteTerritory?.id,
      });

      editTerritory(null);
      setSelectTerritories([]);
      setActiveTerritory(null);
      setDeleteTerritory(null);
      toggleManageTerritory(null);
      onToggleDrawer(null);

      // resets the map to the default state
      handleReset();
      setDrawingManagerDrawMode(DRAW_MODES.MOVE);
    } catch (err) {
      log.exception('Error deleting territory', err.message);
      setDeleteError(err.message);
    }
  };

  const handleCancel = () => {
    editTerritory(null);
    toggleCreateTerritoryMode(false);
    setDrawingManagerDrawMode(DRAW_MODES.MOVE);
    territorySelected(user?.last_territory || null);
    toggleManageTerritory(null);
    onToggleDrawer(null);
    setAdhocTerritory(null);
  };

  const handleReset = () => {
    setClearOverlay(true);
    setAdhocTerritory(null);
    removeTempSelectedCounties({ removeAll: true });
    removeTempSelectedZipCodes({ removeAll: true });
    removeTempTerritoryCoordinates({ removeAll: true });
    removeTempSelectedStates({ removeAll: true });
    // if (toggleDrawer?.drawer !== DISCOVERY_BLADE_NAMES.REP_LIST) {
    //   onToggleDrawer(DISCOVERY_BLADE_NAMES.REP_LIST);
    // }
  };

  const combinedData = useCallback(() => {
    const currentTerritories =
      myTerritoryData && myTerritoryData?.length > 0 ? myTerritoryData : [];
    const repTerritories = currentTerritories
      ?.filter((rep) => rep.owner?.id !== user.id)
      ?.sort((a, b) => {
        if (a.is_canonical !== b.is_canonical) {
          return a.is_canonical ? -1 : 1;
        }
        return a.name.localeCompare(b.name);
      })
      ?.map((territory) => ({
        ...territory,
        repName: `${territory.owner.first_name} ${territory.owner.last_name}`,
      }))
      ?.sort((a, b) => a.repName.localeCompare(b.repName));

    const myTerritories = currentTerritories
      ?.filter((territory) => territory.owner?.id === user.id)
      .concat({
        id: NO_TERRITORY_ACTIVE_SELECTION.id,
        value: NO_TERRITORY_ACTIVE_SELECTION.id,
        name: NO_TERRITORY_ACTIVE_SELECTION.name,
      })
      ?.sort((a, b) => {
        if (a.value === 'USE_MAP_NO_TERRITORY') return -1;

        return a.name.localeCompare(b.name);
      });

    myTerritories?.unshift({
      id: 'addNew',
      value: 'addNew',
      name: 'Add a Territory',
    });

    const isManagerWithReps =
      (Boolean(user?.company?.manager) ||
        Boolean(user?.permissions?.is_manager)) &&
      repTerritories?.length > 0;

    return isManagerWithReps
      ? [...myTerritories, ...repTerritories]
      : myTerritories;
  }, [myTerritoryData, user]);

  const isModifyingTerritory = editingTerritory || createNewTerritoryMode;
  const isLoading =
    isLoadingTerritories ||
    isSavingTerritory ||
    isEditingTerritory ||
    isDeletingTerritory ||
    isLoadingGetTerritory ||
    isFetching;

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '24rem',
        top: '0.5rem',
        left: '0.5rem',
      }}
    >
      {!isModifyingTerritory && (
        <FormGroup row sx={{ width: '100%' }}>
          <FormControl sx={{ flex: 1 }}>
            <Autocomplete
              disableClearable
              loading={isLoading}
              loadingText="Loading Territories..."
              options={combinedData() || []}
              value={activeTerritory ? activeTerritory : null}
              getOptionLabel={(option) => getTerritoryDisplayName(option)}
              sx={{
                cursor: 'pointer',
                '& .MuiAutocomplete-inputRoot>input': {
                  cursor: 'pointer',
                },
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              groupBy={(option) => option?.repName}
              renderGroup={(params) => (
                <Box key={params.key}>
                  {params.group && (
                    <TerritoryGroup
                      params={params}
                      activeTerritory={activeTerritory}
                    />
                  )}
                  {!params.group && params.children}
                </Box>
              )}
              renderOption={(props, option) => (
                <TerritoryItem
                  key={option.id}
                  props={props}
                  option={option}
                  activeTerritory={activeTerritory}
                />
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  aria-label="Territory Selection Dropdown"
                  placeholder="Select a Territory"
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      flex: 1,
                      backgroundColor: 'white',
                      borderTopRightRadius: activeTerritory ? 0 : '0.3rem',
                      borderBottomRightRadius: activeTerritory ? 0 : '0.3rem',
                      borderTopLeftRadius: '0.3rem',
                      borderBottomLeftRadius: '0.3rem',
                      fontSize: '0.9rem',
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: grey[400],
                        borderWidth: '1px !important',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: grey[400],
                        borderWidth: '1px !important',
                      },
                    },
                  }}
                />
              )}
              ListboxProps={{
                sx: {
                  height: '30rem',
                  maxHeight: '35rem',
                  '& :hover': {
                    backgroundColor: `${theme.palette.highlight.light} !important`,
                  },
                },
              }}
              onChange={(e, value) => {
                if (value.value === 'addNew') {
                  return addTerritory();
                } else {
                  return territorySelected(value);
                }
              }}
              clearOnBlur
            />
          </FormControl>
          <FormControl>
            {activeTerritory &&
              activeTerritory.id !== NO_TERRITORY_ACTIVE_SELECTION.id && (
                <StyledLoadingButton
                  variant="outlined"
                  sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    borderTopRightRadius: '0.3rem',
                    borderBottomRightRadius: '0.3rem',
                    padding: '0.5rem',
                    minWidth: '2.5rem',
                    maxWidth: '2.5rem',
                    maxHeight: '2.4rem',
                  }}
                  loading={isLoading}
                  disabled={
                    (activeTerritory.is_canonical && isRegularUser) || isLoading
                  }
                  onClick={() => {
                    toggleManageTerritory(MANAGE_TERRITORY_MODES.EDIT);
                  }}
                  aria-label="Territory Settings"
                >
                  {!isLoading && <Icon icon={faPencil} color={grey[700]} />}
                </StyledLoadingButton>
              )}
          </FormControl>
        </FormGroup>
      )}

      {!manageTerritoryMode && !activeTerritory && (
        <Card style={{ marginTop: '0.25rem' }}>
          <CardContent>
            <Typography sx={{ fontSize: '1rem', fontWeight: 500 }}>
              Select or create a territory to begin your filtered search.
            </Typography>
          </CardContent>
        </Card>
      )}

      {(editingTerritory || createNewTerritoryMode) && (
        <TerritoryPanelForm
          error={inlineError}
          editingTerritory={editingTerritory}
          isCanonical={isCanonical}
          isLoading={isLoading}
          newTerritoryName={newTerritoryName}
          onCancel={handleCancel}
          onReset={handleReset}
          saveEditTerritory={saveEditTerritory}
          saveNewTerritory={saveNewTerritory}
          selectedTerritoryRep={selectedTerritoryRep}
          setDeleteTerritory={setDeleteTerritory}
          setIsCanonical={setIsCanonical}
          setNewTerritoryName={setNewTerritoryName}
          setSelectedTerritoryRep={setSelectedTerritoryRep}
          showValidation={showValidation}
          territory={activeTerritory || null}
          showRepList={showRepList}
          showTerritory={showTerritory}
          toggleDrawer={toggleDrawer}
          onToggleDrawer={onToggleDrawer}
          adhocTerritory={adhocTerritory}
        />
      )}

      <TerritoryDeletionDialog
        open={!!deleteTerritory}
        handleClose={toggleDeleteConfirmation}
        territory={deleteTerritory}
        error={deleteError}
        isDeleting={isDeletingTerritory}
        setIsDeleting={setDeleteTerritory}
        confirm={confirmDeleteTerritory}
      />
    </Box>
  );
};

export default TerritoryPanel;
