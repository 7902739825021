import { useEffect, useLayoutEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useDebounce } from 'use-debounce';
import { getSearchQueryParamsForAPI } from 'src/utils/url.helpers';
import { grey } from '@mui/material/colors';
import { Box, styled, CircularProgress } from '@mui/material';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/pro-solid-svg-icons';
import {
  NO_TERRITORY_ACTIVE_SELECTION,
  TerritoryPanel,
  ResultsBlade,
  RepsListBlade,
} from './components';
import {
  MapProvider,
  useAuth,
  useProspectSearch,
  useLayoutControl,
  useMap,
  useTable,
} from 'src/context';
import { SelectedProspectProvider } from './context';
import {
  AddProviderDialog,
  BladeToggle,
  Map,
  MedScoutLoadingIcon,
  TerritoryBlade,
} from 'src/components';
import { useGetDashboards, useGetMapSearchResults } from 'src/hooks';
import { DISCOVERY_BLADE_NAMES } from './constants';
import { exists, hasContent } from 'src/utils';

interface RootProps {
  open?: boolean;
}

const drawerWidth = 40;

const MapContentContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '100%',
});

const MapContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<RootProps>(({ theme, open }) => ({
  flex: 1,
  width: '100%',
  position: 'relative',
  transition: theme.transitions.create('margin', {
    easing: 'cubic-bezier(0,0.75,0.25,1)',
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: 'cubic-bezier(0,0.75,0.25,1)',
    }),
    marginRight: -2, // overlaps drawer by 2px
  }),
}));

const LoadingContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  zIndex: 1,
  backgroundColor: 'rgba(0,0,0,0.15)',
});

const LoadingContent = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '0.625rem',
  paddingRight: '0.625rem',
  paddingLeft: '0.625rem',
});

const FetchingContainer = styled(Box)({
  position: 'absolute',
  bottom: '2rem',
  left: '1rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '2rem',
  padding: '0.5rem',
  fontWeight: 500,
  border: `1px solid ${grey[500]}`,
  borderRadius: '0.5rem',
  backgroundColor: 'white',
  zIndex: 1,
});

const MapView = () => {
  const { data: dashboardData } = useGetDashboards();
  const router = useRouter();
  const { user } = useAuth();

  const { dateRange } = useTable();

  const currentTable = dateRange?.every_table
    ? dateRange?.every_table
    : dateRange['discovery'];

  const [mapState, setMapState] = useState(null);

  const [searchQueryParams, setSearchQueryParams] = useState({
    queryParams: '',
    enabled: false,
  });
  const [newProviders, setNewProviders] = useState(null);

  const [hoveredProviderId, setHoveredProviderId] = useState(null);

  const [adhocTerritory, setAdhocTerritory] = useState(null);

  // prevent massive request cycling due to micro movements in fast succession
  const [debouncedMapState] = useDebounce(mapState, 1000);
  const {
    prospectSearch,
    prospectFilters: filters,
    prospectType,
    prospectMode,
  } = useProspectSearch();
  const {
    editingTerritory,
    selectedTerritories,
    createNewTerritoryMode,
    boundariesLoading,
    territoriesLoading,
  } = useMap();

  const [debouncedQueryParams] = useDebounce(searchQueryParams, 2_000);

  const [toggleDrawer, setToggleDrawer] = useState<{
    drawer: string;
    open: boolean;
  } | null>(null);

  const enableSearch =
    (!!prospectSearch?.id || !!selectedTerritories[0]?.id) &&
    debouncedQueryParams.enabled;

  const {
    data: mapSearchResults,
    isLoading,
    isFetching,
  } = useGetMapSearchResults({
    queryParams: debouncedQueryParams.queryParams,
    enabled: !!enableSearch && debouncedQueryParams?.enabled,
    mapState: debouncedMapState,
    company_id: user?.company?.id,
    dateRange: {
      lte: currentTable?.lte,
      gte: currentTable?.gte,
    },
  });

  useEffect(() => {
    let containsNonExclusionPaymentsFilter = false;

    const groupedFiltersByContext = filters.reduce(
      (result, { context, value }) => {
        if (!containsNonExclusionPaymentsFilter) {
          // [id, min, max] value pattern for payments, when max is defined as 0 this is an 'exclusion payment filter'
          containsNonExclusionPaymentsFilter =
            context !== 'payments' ? true : (value?.['2'] ?? null) !== 0;
        }

        if (!result[context]) {
          result[context] = context === 'year' ? value : [value];
        } else {
          result[context] = [...result[context], value];
        }

        return result;
      },
      {}
    );

    const sortedParams = Object.keys(groupedFiltersByContext).reduce(
      (result, key) => {
        const currentParams = groupedFiltersByContext[key];
        if (Array.isArray(currentParams)) {
          result[key] = Array.from(new Set(currentParams)).sort();
        } else {
          result[key] = currentParams;
        }
        return result;
      },
      {}
    );

    const selectedTerritoryIds = selectedTerritories.reduce(
      (result, territory) => {
        if (territory?.id?.toString() !== NO_TERRITORY_ACTIVE_SELECTION.id) {
          result.push(territory?.id);
        }
        return result;
      },
      []
    );

    const encodedParams = getSearchQueryParamsForAPI({
      type: prospectType,
      mode: prospectMode,
      ex_pr: 0,
      map: selectedTerritoryIds,
      zoom: mapState?.zoom || 4,
      ...sortedParams,
    });

    const enabled =
      containsNonExclusionPaymentsFilter &&
      exists(selectedTerritories) &&
      exists(filters);

    setSearchQueryParams({
      queryParams: encodedParams,
      enabled,
    });

    router.push(`/search${encodedParams}`, undefined, { shallow: true });
  }, [prospectType, prospectMode, filters, selectedTerritories]);

  const noop = {};

  const addToList = async (providers, fromMap = false, onSuccess = noop) => {
    setNewProviders({
      providers: Array.isArray(providers)
        ? structuredClone(providers)
        : [{ ...providers }],
      ui_fromMap: fromMap,
      onSuccess,
    });
  };

  const performMapZoom = (mapState) => {
    if (!mapState) return;

    setMapState(mapState);
  };

  const onResultHover = (provider_id) => {
    setHoveredProviderId(provider_id);
  };

  const isManagerOrStaff =
    user?.company?.manager ||
    user?.permissions?.is_manager ||
    (user?.is_staff && user?.is_superuser);

  const showPoints =
    hasContent(filters) &&
    !editingTerritory &&
    !createNewTerritoryMode &&
    selectedTerritories.length > 0;

  useEffect(() => {
    const showRightSidebarResults =
      exists(selectedTerritories) &&
      !editingTerritory &&
      !createNewTerritoryMode;

    if (showRightSidebarResults) {
      setToggleDrawer({
        drawer: DISCOVERY_BLADE_NAMES.RESULTS,
        open: showRightSidebarResults,
      });
    }
  }, [selectedTerritories, editingTerritory, createNewTerritoryMode]);

  useEffect(() => {
    const showRightSidebarTerritoryList =
      isManagerOrStaff && (!!editingTerritory || createNewTerritoryMode);
    const isDrawerOpen =
      toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.REP_LIST &&
      toggleDrawer?.open;

    if (showRightSidebarTerritoryList && !isDrawerOpen) {
      setToggleDrawer({
        drawer: DISCOVERY_BLADE_NAMES.REP_LIST,
        open: false,
      });
    }
  }, [
    editingTerritory,
    isManagerOrStaff,
    selectedTerritories,
    createNewTerritoryMode,
  ]);

  const handleToggleDrawer = (name = null, open = null) => {
    setToggleDrawer((prev) => {
      if (!name) return null;
      if (prev?.drawer === name) {
        return {
          ...prev,
          open: !prev.open,
        };
      }
      return {
        drawer: name,
        open: !!open,
      };
    });
  };

  return (
    <SelectedProspectProvider>
      <MapContentContainer>
        <MapContainer open={toggleDrawer?.open}>
          {(isLoading || boundariesLoading || territoriesLoading) && (
            <LoadingContainer>
              <LoadingContent>
                <MedScoutLoadingIcon />
              </LoadingContent>
            </LoadingContainer>
          )}
          {!isLoading && isFetching && (
            <FetchingContainer>
              <CircularProgress size="small" />
            </FetchingContainer>
          )}

          <Map
            center={null}
            points={showPoints ? mapSearchResults : []}
            mapZoomCallback={performMapZoom}
            onAddToList={(provider) => {
              const fromMap = true;
              addToList([provider], fromMap);
            }}
            showCreateTools={createNewTerritoryMode}
            containToTerritories
            hoveredProviderId={hoveredProviderId}
          >
            {toggleDrawer && (
              <BladeToggle
                open={toggleDrawer?.open}
                drawer={toggleDrawer?.drawer}
                toggleOpen={() => handleToggleDrawer(toggleDrawer?.drawer)}
              >
                <Icon
                  icon={
                    toggleDrawer?.open ? faAngleDoubleRight : faAngleDoubleLeft
                  }
                />
              </BladeToggle>
            )}
          </Map>
        </MapContainer>

        <ResultsBlade
          open={
            toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.RESULTS &&
            toggleDrawer?.open
          }
          onClose={() => handleToggleDrawer(toggleDrawer?.drawer)}
          addToList={addToList}
          contentType={prospectType}
          searchQueryParams={searchQueryParams}
          mapState={debouncedMapState}
          onResultHover={onResultHover}
          width={drawerWidth}
          updateOnMapMove={!editingTerritory && !createNewTerritoryMode}
        />
        <RepsListBlade
          open={
            toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.REP_LIST &&
            toggleDrawer?.open
          }
          onClose={() => handleToggleDrawer(toggleDrawer?.drawer)}
          width={drawerWidth}
        />
        <TerritoryBlade
          territoryId={'adhoc-territory'}
          open={
            !!adhocTerritory &&
            toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.TERRITORY &&
            toggleDrawer?.open
          }
          onClose={() => handleToggleDrawer(toggleDrawer?.drawer)}
          dashboardId={dashboardData?.results[0]?.id}
          adhoc={adhocTerritory}
          width={drawerWidth}
        />
        <TerritoryPanel
          showRepList={
            toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.REP_LIST &&
            toggleDrawer?.open
          }
          showTerritory={
            !!adhocTerritory &&
            toggleDrawer?.drawer === DISCOVERY_BLADE_NAMES.TERRITORY &&
            toggleDrawer?.open
          }
          toggleDrawer={toggleDrawer}
          onToggleDrawer={handleToggleDrawer}
          adhocTerritory={adhocTerritory}
          setAdhocTerritory={setAdhocTerritory}
        />
      </MapContentContainer>

      {newProviders && (
        <AddProviderDialog
          onHide={() => setNewProviders(null)}
          newProviders={newProviders}
        />
      )}
    </SelectedProspectProvider>
  );
};

const Discovery = () => {
  return (
    <MapProvider>
      <MapView />
    </MapProvider>
  );
};

export default Discovery;
