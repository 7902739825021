import { Box, ToggleButtonGroup } from '@mui/material';
import ToolTipToggleButton from './ToolTipToggleButton';

import { DRAW_MODES } from 'src/components/Map/constants';

import { faCrosshairs, faDrawPolygon } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { useMap } from 'src/context';
import { useEffect } from 'react';

const ToolArea = () => {
  const buttons = [
    {
      name: 'State',
      description: 'Select territory area by State.',
      value: DRAW_MODES.STATE,
      icon: faCrosshairs,
    },
    {
      name: 'County',
      description: 'Select territory area by County.',
      value: DRAW_MODES.COUNTY,
      icon: faCrosshairs,
    },
    {
      name: 'Zip',
      description: 'Select territory area by ZIP.',
      value: DRAW_MODES.ZIP,
      icon: faCrosshairs,
    },
    {
      name: 'Draw',
      description:
        'Click on the map to begin drawing the border of your territory.',
      value: DRAW_MODES.DRAW_POLY,
      icon: faDrawPolygon,
    },
  ];

  const {
    drawingManagerDrawMode,
    setDrawingManagerDrawMode,
    selectedTerritories,
    createNewTerritoryMode,
  } = useMap();

  const isNew =
    selectedTerritories[0]?.polygons?.length > 0 || createNewTerritoryMode;
  useEffect(() => {
    if (selectedTerritories.length > 0) {
      const source = selectedTerritories[0].source;
      const drawMode = determineDrawMode(source);
      setDrawingManagerDrawMode(drawMode);
    }
  }, [selectedTerritories]);

  const determineDrawMode = (source) => {
    if (createNewTerritoryMode) {
      return DRAW_MODES.STATE;
    } else if (source.counties?.length > 0) {
      return DRAW_MODES.COUNTY;
    } else if (source.states?.length > 0) {
      return DRAW_MODES.STATE;
    } else if (source.zip_codes?.length > 0) {
      return DRAW_MODES.ZIP;
    } else {
      return DRAW_MODES.DRAW_POLY;
    }
  };

  function handleToggle(e: React.MouseEvent<HTMLElement>, nextView: string) {
    if (!nextView) return;
    setDrawingManagerDrawMode(nextView);
  }

  return (
    <Box style={{ marginBottom: '0.5rem', width: '100%' }}>
      <ToggleButtonGroup
        value={drawingManagerDrawMode}
        exclusive
        onChange={handleToggle}
        sx={{ width: '100%', marginTop: '0.5rem' }}
      >
        {buttons.map((button, index) => (
          <ToolTipToggleButton
            key={index}
            title={button.name}
            icon={
              <Icon icon={button.icon} style={{ marginRight: '0.25rem' }} />
            }
            description={
              <div>
                {isNew
                  ? button.description
                  : `Toolbar is disabled for older territories.`}
              </div>
            }
            value={button.value}
            aria-label={button.name}
            sx={{ width: '100%', border: '1px solid #151515' }}
          >
            <Icon icon={button.icon} style={{ marginRight: '0.25rem' }} />
            {button.name}
          </ToolTipToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToolArea;
